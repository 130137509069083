
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  setup(props, { emit }) {
    // Autocomplete value
    const autocompleteValue = ref("");
    // Declaration of options for autocomplete suggestions
    const result = ref<string[]>([]);

    const handleSearch = (val: string) => {
      emit("onSearchInput", val);
    };

    return {
      autocompleteValue,
      result,
      handleSearch,
    };
  },
  emits: ["onSearchInput"],
  props: {
    // Title of the Autocomplete input
    placeholder: {
      type: String,
    },
    dropdownClassName: {
      type: String,
    },
  },
});
