import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23bc7274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "global-search-input__search-result-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_AutoCompleteInput = _resolveComponent("AutoCompleteInput")!

  return (_openBlock(), _createBlock(_component_AutoCompleteInput, {
    "dropdown-class-name": "global-search-input__search-dropdown",
    class: "global-search-input",
    placeholder: _ctx.t('Search for pool, transaction, block, epoch'),
    onOnSearchInput: _ctx.onSearchInput,
    customResults: _ctx.resultsRef
  }, {
    prefix: _withCtx(() => [
      _createVNode(_component_SearchOutlined)
    ]),
    results: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultsRef, (item, index) => {
        return (_openBlock(), _createBlock(_component_a_select_option, {
          key: index,
          onClick: ($event: any) => (_ctx.onSearchResultClicked(item))
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item?.itemType), 1),
            _createTextVNode(" " + _toDisplayString(item?.label), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["placeholder", "onOnSearchInput", "customResults"]))
}