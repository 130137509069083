/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum PoolsUpdateType {
  DEREGISTRATION = "DEREGISTRATION",
  REGISTRATION = "REGISTRATION",
}

export enum SearchItemType {
  ADDRESS = "ADDRESS",
  BLOCK = "BLOCK",
  EPOCH = "EPOCH",
  EPOCH_SLOT = "EPOCH_SLOT",
  POOL = "POOL",
  SLOT = "SLOT",
  TRANSACTION = "TRANSACTION",
}

export enum StakeAddressType {
  DEREGISTRATION = "DEREGISTRATION",
  REGISTRATION = "REGISTRATION",
}

export interface AccountFiltersInput {
  pagination?: SimplePaginationInput | null;
}

export interface BlockFiltersInput {
  epochNo?: number | null;
  pagination: PaginationInput;
}

export interface EpochFiltersInput {
  pagination?: SimplePaginationInput | null;
}

export interface PaginationInput {
  timestamp: any;
  offset?: number | null;
  pageSize?: number | null;
}

export interface PoolFiltersInput {
  pagination?: SimplePaginationInput | null;
}

export interface PoolHistoryFiltersInput {
  poolHash: string;
  pagination?: SimplePaginationInput | null;
}

export interface SimplePaginationInput {
  offset?: number | null;
  pageSize?: number | null;
}

export interface TransactionFiltersInput {
  poolId?: string | null;
  blockId?: string | null;
  epochNo?: number | null;
  addressHash?: string | null;
  pagination: PaginationInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
