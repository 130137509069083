
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import AutoCompleteInput from "@/shared/components/forms/AutoCompleteInput.vue";
import { useQuery } from "@vue/apollo-composable";
import { searchGql } from "@/api/search/search";
import {
  Search,
  Search_search_SearchResult,
} from "@/api/search/__generated__/Search";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import useDebounce from "@/shared/composables/debounce";
import { isEmpty, flatMap } from "lodash";
import { useRouter } from "vue-router";
import { SearchItemType } from "../../../../__generated__/globalTypes";
import routeNames from "@/web/router/routeNames";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const enableSearchQuery = ref(false);
    const searchInput = ref();
    const router = useRouter();
    const searchQuery = useQuery<Search>(
      searchGql,
      () => ({
        searchInput: searchInput.value,
      }),
      () => ({
        debounce: 500,
        fetchPolicy: "no-cache",
        enabled: enableSearchQuery.value,
      })
    );
    const parsedGqlSearch = computed(() =>
      parseGqlResponse<Search_search_SearchResult>(
        "SearchResult",
        searchQuery?.result?.value
      )
    );

    const resultsRef = ref();
    watch(
      () => parsedGqlSearch.value,
      (results) => {
        if (!isEmpty(results?.data)) {
          resultsRef.value = flatMap(results?.data, parseResult);
        }
      }
    );

    const parseResult = (resultList) => {
      if (resultList != null && resultList != "SearchResult") {
        const list = resultList
          .filter((item) =>
            // TODO: Update filter once we support more page details in the future
            [
              SearchItemType.BLOCK,
              SearchItemType.TRANSACTION,
              SearchItemType.POOL,
              SearchItemType.EPOCH,
            ].includes(item.itemType)
          )
          .map((item) => {
            return {
              id: item?.id,
              label: item?.label,
              itemType: item?.itemType,
            };
          });
        return [...list];
      } else {
        return [];
      }
    };

    const onSearch = (inputVal) => {
      searchInput.value = inputVal;
      if (inputVal != null && inputVal != "") {
        enableSearchQuery.value = true;
        searchQuery.start();
      } else {
        searchQuery.stop();
        resultsRef.value = null;
      }
    };

    const onSearchResultClicked = (searchItem) => {
      if (searchItem.itemType === SearchItemType.BLOCK) {
        router.push({
          name: routeNames.blockDetails,
          params: {
            hash: searchItem.id,
          },
        });
      }

      if (searchItem.itemType === SearchItemType.EPOCH) {
        router.push({
          name: routeNames.epochDetails,
          params: {
            hash: searchItem.id,
          },
        });
      }

      if (searchItem.itemType === SearchItemType.TRANSACTION) {
        router.push({
          name: routeNames.transactionDetails,
          params: {
            hash: searchItem.id,
          },
        });
      }

      if (searchItem.itemType === SearchItemType.POOL) {
        router.push({
          name: routeNames.poolDetails,
          params: {
            hash: searchItem.id,
          },
        });
      }
    };

    return {
      t,
      onSearchResultClicked,
      onSearchInput: useDebounce(onSearch, 500),
      resultsRef,
    };
  },
  components: {
    AutoCompleteInput,
  },
});
