import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "autocomplete-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_auto_complete, {
      value: _ctx.autocompleteValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.autocompleteValue) = $event)),
      onSearch: _ctx.handleSearch,
      "dropdown-class-name": _ctx.dropdownClassName
    }, {
      options: _withCtx(() => [
        _renderSlot(_ctx.$slots, "results")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_input, { placeholder: _ctx.placeholder }, {
          prefix: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prefix")
          ]),
          _: 3
        }, 8, ["placeholder"])
      ]),
      _: 3
    }, 8, ["value", "onSearch", "dropdown-class-name"])
  ]))
}