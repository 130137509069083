import gql from "graphql-tag";

export const searchGql = gql`
  query Search($searchInput: String!) {
    search(q: $searchInput) {
      ... on SearchResult {
        addressResults {
          id
          label
          itemType
        }
        poolResults {
          id
          label
          itemType
        }
        transactionResults {
          id
          label
          itemType
        }
        blockResults {
          id
          label
          itemType
        }
        epochResults {
          id
          label
          itemType
        }
        slotResults {
          id
          label
          itemType
        }
        epochSlotResults {
          id
          label
          itemType
        }
      }
      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
