export default function (callback, delay = 500) {
  let timeoutRef: ReturnType<typeof setTimeout> | null;

  const debounce = (value) => {
    if (timeoutRef === null) {
      callback(value);
    } else {
      clearTimeout(timeoutRef);
    }
    timeoutRef = setTimeout(() => {
      callback(value);
    }, delay);
  };

  return debounce;
}
